import { Environment } from './environment.interface';

export const environment: Environment = {
	// BASICS
	name: 'production',
	production: true,

	// URLS
	api_url: 'https://api.click-engage.co.uk/',
	search_api_url: 'https://search.click-engage.co.uk/',
	vehicle_image_url: 'https://images.clickdealer.co.uk/',
	brand_image_url: 'https://images.clickdealer.co.uk/brands/',
	deploy_protocol: 'https://',
	deploy_domain: 'click-engage.co.uk',
	website_domain: null,
	evolution_idd_url: 'https://www.evolutionfunding.com/public/initial-disclosure-document/',
	motion_idd_url: 'https://mycreditnet.co.uk/Register/GetInitialDisclosureDocument',

	// KEYS
	pusher_key: '3a24f34113e452186e01',
	loqate_api_key: 'EU18-UZ17-YX88-NW85',
   
	// VISITOR CHAT
	visitor_chat_account_id: 4738,
	visitor_chat_domain: null,

	// HELLOSIGN
	hellosign_client_id: 'b17aeb54f7265e3531c802dc0ee5b7de',

	// GTM SETTINGS (ENV/AUTH NOT NEEDED FOR PRODUCTION)
	gtm_container_id: 'GTM-WC3R83P',
	gtm_environment_id: undefined,
	gtm_environment_auth: undefined,

	// APM SETTINGS
	apm_name: 'prod',
};
